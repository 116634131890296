import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { PrismicRichText } from '@prismicio/react';

import Layout from '../components/Layout';

const Container = styled(Box)`
  font-size: 18px;
  line-height: 1.6;

  p {
    margin-bottom: 25px;
  }
`;

const PrivacyPage: React.FC<any> = props => {
  const { title, body } = props.data.prismicPage.data;

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container width={1} maxWidth={1000} m="0 auto" p="200px 8% 100px">
        <Text fontSize={50} lineHeight={1.1} mb={30}>
          {title}
        </Text>
        {body.map((item: any, i: number) => (
          <Fragment key={i}>
            {item.__typename === 'PrismicPageDataBodyText' && (
              <PrismicRichText field={item.primary.text.richText} />
            )}
          </Fragment>
        ))}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "privacy" }) {
      data {
        title
        body {
          __typename
          ... on PrismicPageDataBodyText {
            primary {
              __typename
              text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default PrivacyPage;
